(function() {
  'use strict';

  attachHandlers();
})();

function attachHandlers() {
  // Add event listeners for Filters.
  const filters = document.querySelectorAll(
    '.c-hero--homepage .c-form-item__select'
  );
  if (filters) {
    for (let i = 0; i < filters.length; i++) {
      const filter = filters[i];
      filter.addEventListener('change', handleFilter);
    }
  }
}

function handleFilter(event) {
  if (event instanceof Event) {
    event.preventDefault();
  }
  const form = closest(event.target, 'form');
  form.submit();
}

/**
 * Get the nearest parent node of an element.
 *
 * @param {HTMLNode} elem - A DOM element.
 * @param {string} selector - CSS selector.
 * @returns {HTMLNode} Nearest parent node that matches the selector.
 */
function closest(elemParam, selector) {
  let elem = elemParam.parentNode;

  const matchesSelector = _getMatchesMethod(elem);
  let match;

  try {
    while (elem) {
      if (matchesSelector.bind(elem)(selector)) {
        match = elem;
      } else {
        elem = elem.parentNode;
      }

      if (match) {
        return elem;
      }
    }
  } catch (err) {
    return null;
  }

  return null;
}

/**
 * Search for support of the matches() method by looking at
 * browser prefixes.
 * @param {HTMLNode} elem
 *   The element to check for support of matches() method.
 * @returns {Function} The appropriate matches() method of elem.
 */
function _getMatchesMethod(elem) {
  return (
    elem.matches ||
    elem.webkitMatchesSelector ||
    elem.mozMatchesSelector ||
    elem.msMatchesSelector
  );
}
